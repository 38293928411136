<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="姓名:">
            <el-input 
							v-model.trim="searchForm.name" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="毕业院校:">
            <el-input 
							v-model.trim="searchForm.collegename" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="专业:">
            <el-input 
							v-model.trim="searchForm.majorname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="身份证号:">
            <el-input 
							v-model.trim="searchForm.idcard" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="学历:">
            <el-input 
							v-model.trim="searchForm.education" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="是否公办在职:">
            <el-select
              v-model="isincumbency[searchForm.isincumbency]"
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectRef"
              clearable
            >
              <el-option value="在职"></el-option>
              <el-option value="不在职"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="姓名" align="center" width="120px">
        <template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.name
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="性别" prop="sex" align="center" />
      <el-table-column
        label="身份证"
        prop="idcard"
        align="center"
        width="200"
      />
      <el-table-column label="职称" prop="positional" align="center" />
      <el-table-column
        label="学历"
        prop="education"
        align="center"
        width="120"
      />
      <el-table-column
        label="毕业院校"
        prop="collegename"
        align="center"
        width="180"
      />
      <el-table-column
        label="专业"
        prop="majorname"
        align="center"
        width="200"
      />
      <el-table-column
        label="公办在职"
        prop="isincumbency"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.isincumbency == 1 ? "在职" : "不在职" }}
        </template>
      </el-table-column>
      <el-table-column label="录入人" prop="username" align="center" width="120px"/>
      <el-table-column label="所属教学点" prop="schoolorgname" align="center" width="150px"/>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看 -->
    <el-dialog
      class="btn-2b5a95"
      width="1000px"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="姓名:">
          {{ dynamicValidateForm.name }}
        </el-form-item>
        <el-form-item label="性别:">
          {{ dynamicValidateForm.sex }}
        </el-form-item>
        <el-form-item label="身份证号:">
          {{ dynamicValidateForm.idcard }}
        </el-form-item>
        <el-form-item label="职称:">
          {{ dynamicValidateForm.positional }}
        </el-form-item>
        <el-form-item label="学历:">
          {{ dynamicValidateForm.education }}
        </el-form-item>
        <el-form-item label="毕业院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="学位院校:">
          {{ dynamicValidateForm.acollegename }}
        </el-form-item>
        <el-form-item label="学位:">
          {{ dynamicValidateForm.academic }}
        </el-form-item>
        <el-form-item label="学位专业:">
          {{ dynamicValidateForm.amajorname }}
        </el-form-item>
        <el-form-item label="擅长课程:">
          {{ dynamicValidateForm.coursesname }}
        </el-form-item>
        <el-form-item label="上岗资格:">
          {{ dynamicValidateForm.qualifications }}
        </el-form-item>
        <el-form-item label="公办在职:">
          {{ isincumbency[dynamicValidateForm.isincumbency] }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.username }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="备注:">
          {{ dynamicValidateForm.remark }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			width="60%"
			:title="dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名:" prop="name">
          <el-input v-model.trim="dynamicValidateForm.name" clearable/>
        </el-form-item>
        <el-form-item label="性别:" prop="sex">
          <el-select v-model="dynamicValidateForm.sex" clearable>
            <el-option value="男"></el-option>
            <el-option value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否在职:" prop="isincumbency">
          <el-select v-model="dynamicValidateForm.isincumbency">
            <el-option value="1" label="是"></el-option>
            <el-option value="0" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号:" prop="idcard">
          <el-input v-model.trim="dynamicValidateForm.idcard" clearable/>
        </el-form-item>
        <el-form-item label="学位:" prop="academic">
          <el-input v-model.trim="dynamicValidateForm.academic" clearable/>
        </el-form-item>
        <el-form-item label="学位院校:" prop="acollegename">
          <el-input v-model.trim="dynamicValidateForm.acollegename" clearable/>
        </el-form-item>
        <el-form-item label="专业:" prop="amajorname">
          <el-input v-model.trim="dynamicValidateForm.amajorname" clearable/>
        </el-form-item>
        <el-form-item label="擅长课程:" prop="coursesname">
          <el-input v-model.trim="dynamicValidateForm.coursesname" clearable/>
        </el-form-item>
        <el-form-item label="上岗资格:" prop="qualifications">
          <el-input v-model.trim="dynamicValidateForm.qualifications" clearable/>
        </el-form-item>
        <el-form-item label="职称:" prop="positional">
          <el-input v-model.trim="dynamicValidateForm.positional" clearable/>
        </el-form-item>
        <el-form-item label="学历:" prop="education">
          <el-input v-model.trim="dynamicValidateForm.education" clearable/>
        </el-form-item>
        <el-form-item label="毕业院校:" prop="collegename">
          <el-input v-model.trim="dynamicValidateForm.collegename" clearable/>
        </el-form-item>
        <el-form-item label="专业:" prop="majorname">
          <el-input v-model.trim="dynamicValidateForm.majorname" clearable/>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input 
						type="textarea" 
						v-model.trim="dynamicValidateForm.remark"
						:rows="6"
						placeholder="请输入内容"
					 />
        </el-form-item>

        <div
          v-for="(item, i) in dynamicValidateForm.moreItems"
          :key="i"
          class="more-form-item-w"
        >
          <el-form-item
            label="标题："
            :prop="'moreItems.' + i + '.title'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.title" clearable></el-input>
          </el-form-item>
          <el-form-item
            label="凭证："
            :prop="'moreItems.' + i + '.fileurl'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-upload
              class="upload-demo"
              :action="$store.state.global.baseUrl"
              multiple
              :before-upload="m_beforeUpload"
              :http-request="(index) => m_uploadFile(i)"
              :show-file-list="false"
            >
              <el-button type="primary" class="select-btn">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                {{ dynamicValidateForm.moreItems[i].fileurl }}
              </div>
            </el-upload>
          </el-form-item>
          <el-button
            v-if="i !== 0"
            @click="onRemoveMoreForm(i)"
            style="margin: 0 10px; height: 40px"
            >删除</el-button
          >
        </div>
        <el-button @click="addFormItem">添加一行</el-button>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 上传凭证表单 -->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
      :title="dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showCredentialModal"
      v-if="showCredentialModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="姓名:" prop="name">
          <el-input v-model="dynamicValidateForm.name" disabled />
        </el-form-item>
        <el-form-item label="凭证标题:" prop="title">
          <el-input v-model.trim="dynamicValidateForm.title" clearable/>
        </el-form-item>
        <el-form-item label="凭证路径:" prop="fileurl">
          <el-input v-model="dynamicValidateForm.fileurl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="ontechcredential('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			width="80%"
			title="查看详情："
			:close-on-click-modal='false'
      :visible.sync="showModal"
      v-if="showModal"
      append-to-body
    >
      <div class="select-btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="btnDetailEdit">修改</el-button>
        <el-button type="primary" @click="btnDetailDel">删除</el-button>
      </div>

      <el-table
        :data="showTable.list"
        style="width: 100%"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        ref="moneyTable"
        :cell-style="{ padding: 0 }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="100" />
        <el-table-column label="姓名" prop="name" align="center" />
        <el-table-column label="标题" prop="title" align="center" width="200" />
        <el-table-column label="性别" prop="sex" align="center" />
        <el-table-column
          label="身份证号"
          prop="idcard"
          align="center"
          width="200"
        />
        <el-table-column label="职称" prop="positional" align="center" />
        <el-table-column
          label="学历"
          prop="education"
          align="center"
          width="120"
        />
        <el-table-column
          label="毕业院校"
          prop="collegename"
          align="center"
          width="150"
        />
        <el-table-column
          label="专业"
          prop="majorname"
          align="center"
          width="150"
        />
        <el-table-column label="公办在职" align="center">
          <template slot-scope="scope">
            {{ isincumbency[scope.row.isincumbency] }}
          </template>
        </el-table-column>
        <el-table-column label="录入时间" align="center" width="180">
          <template slot-scope="scope">
            {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="录入人" prop="username" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="100"
        />
        <el-table-column label="预览/下载凭证" align="center" width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="downloadFile(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handleShowChange"
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="showTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!--详情的添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showDetailEditModal"
      v-if="showDetailEditModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="姓名:">
          <el-input v-model="dynamicValidateForm.name" disabled />
        </el-form-item>
        <el-form-item label="凭证标题:">
          <el-input v-model.trim="dynamicValidateForm.title" clearable/>
        </el-form-item>
        <el-form-item label="凭证路径:">
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="uploadFile"
          >
            <el-button size="small" type="primary" class="select-btn"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="ontechteacherdetail('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <Export
      :exportTitle="'师资库管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";

import {
  teacherinfo_list,
  teacherinfo_input,
  teacherinfo_save,
  teacherinfo_delete,
  teacherinfo_export,
  teacherinfo_exportexcel,
  teacherinfo_exportsave,
  teacherinfodetail_list,
  teacherinfodetail_input,
  teacherinfodetail_input2,
  teacherinfodetail_save,
  teacherinfodetail_delete,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _fileupload_save, _filedownload_save } from "@/request/api/public";

export default {
  mixins: [part],
  name: "teacherinfo",
  components: { Export },
  props: {
    exportUrl: {
      default: () => teacherinfo_export,
    },
    exportexcelUrl: {
      default: () => teacherinfo_exportexcel,
    },
    exportsaveUrl: {
      default: () => teacherinfo_exportsave,
    },
  },
  data() {
    return {
      transferDialog: false, //导出框
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      isincumbency: {
        1: "是",
        0: "否",
      }, // 是否在职
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {
        moreItems: [
          {
            title: "",
            fileurl: "",
          },
        ],
      }, //表单
      rules: {
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        name: [
					{ required: true, message: '请输入姓名', trigger: 'blur' },
					{ min: 2, max: 10, message: '长度在 2 到 10 个字', trigger: 'blur' },
					{
						required: true,
						pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
						message: '姓名不支持特殊字符',
						trigger: 'blur'
					}
				],
        sex: [{ trigger: "blur", message: "必填字段", required: true }],
        isincumbency: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        idcard: [
					{ required: true, message: '请输入身份证号', trigger: 'blur' },
					{ min: 15, max: 18, message: '请如实填写18位号码，以供相关人员核对', trigger: 'blur' },
					{
						required: true,
						pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
						message: '请输入正确的身份证号码',
						trigger: 'blur'
					}
				],
        academic: [{ trigger: "blur", message: "必填字段", required: true }],
        acollegename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        amajorname: [{ trigger: "blur", message: "必填字段", required: true }],
        coursesname: [{ trigger: "blur", message: "必填字段", required: true }],
        qualifications: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        positional: [{ trigger: "blur", message: "必填字段", required: true }],
        education: [{ trigger: "blur", message: "必填字段", required: true }],
        collegename: [{ trigger: "blur", message: "必填字段", required: true }],
        majorname: [{ trigger: "blur", message: "必填字段", required: true }],
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      teachTypeList: this.$store.getters.teachType,

      showCredentialModal: false, //上传凭证框

      showTable: {}, //查看详情表格
      showModal: false,

      showDetailEditModal: false, //详情里的修改框

      fds: new FormData(),
    };
  },
  created() {
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
		reset() {
			this.searchForm = {
				name: null,
				collegename: null,
				majorname: null,
				idcard: null,
				education: null,
				isincumbency: null
				
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectRef.blur();
			}
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: teacherinfo_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    handleShowChange(val) {
      this.pageinfo.pageindex = val;
      this.showList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: teacherinfo_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vteacherinfo;
        }
      });
    },

    //一行--上传文件前的方法 click
    m_beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //一行--文件上传
    m_uploadFile(index) {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.moreItems[index].fileurl = res.data.url;
          console.log(this.dynamicValidateForm.moreItems);
        }
      });
    },
    //添加一行 click
    addFormItem() {
      this.dynamicValidateForm.moreItems.push({
        title: "",
        fileurl: "",
      });
    },
    //删除快速新增项 click
    onRemoveMoreForm(index) {
      this.dynamicValidateForm.moreItems.splice(index, 1);
    },
    //快速新增提交 click
    submitAddOrEditForm(formName) {
      this.dynamicValidateForm.title = [];
      this.dynamicValidateForm.fileurl = [];
      for (let i in this.dynamicValidateForm.moreItems) {
        for (let key in this.dynamicValidateForm.moreItems[i]) {
          if (this.dynamicValidateForm.moreItems[i][key]) {
            //有值才push，否则是空字符串
            this.dynamicValidateForm[key].push(
              this.dynamicValidateForm.moreItems[i][key]
            );
          }
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveall();
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        companyorgname: this.$store.state.login.userinfo.companyorgname,
        schoolorgname: this.$store.state.login.userinfo.schoolorgname,
        moreItems: [
          {
            title: "",
            fileurl: "",
          },
        ],
      };
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //上传凭证 click
    btnUploadpz() {
      selectCheck(this.multipleSelection, "上传凭证", false, () => {
        this.dialogTitle = "上传凭证";
        this.dynamicValidateForm = {
          name: this.multipleSelection[0].name,
          title: null,
          fileurl: null,
          teacherinfoid: this.multipleSelection[0].id,
        };
        this.showCredentialModal = true;
      });
    },
    //查看 click
    btnFinddetail() {
      selectCheck(this.multipleSelection, "查看", false, () => {
        this.itemId = this.multipleSelection[0].id;
        this.showList();
      });
    },
    //查看详情的修改 click
    btnDetailEdit() {
      this.dialogTitle = "修改";
      this.dynamicValidateForm = {};
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editDetailRoutePage(this.multipleSelection[0].id);
      });
    },
    //查看详情的删除 click
    btnDetailDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delDetail(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    // 上传文件前的方法 click
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
    //下载 click
    downloadFile(row) {
      //    return
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + row.fileurl;
    },
    //详情的修改保存 api
    ontechteacherdetail() {
      myRequest(
        {
          url: teacherinfodetail_save,
          data: this.dynamicValidateForm,
        },
        {
          that: this,
          modal: "showDetailEditModal",
          methodName: "showList",
        }
      );
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: teacherinfo_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vteacherinfo;
          this.dynamicValidateForm.isincumbency =
            this.isincumbency[this.dynamicValidateForm.isincumbency];
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //isoktime 该字段不用传
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.showAddModal = true;
        }
      });
    },
    //（添加，修改） api
    saveall(formName) {
      myRequest(
        {
          method:'post',
          url: teacherinfo_save,
          data: this.$qs.stringify(this.dynamicValidateForm,{
            arrayFormat:'repeat'
          }) ,
        },
        {
          isRefTableList: true,
          that: this,
          modal: "showAddModal",
        }
      );
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: teacherinfo_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //查看 api
    showList() {
      myRequest({
        url: teacherinfodetail_list,
        data: {
          pagesize: this.pageinfo.pagesize,
          pageindex: this.pageinfo.pageindex,
          teacherinfoid: this.itemId,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.showTable = res.data.data;
          this.showModal = true;
        }
      });
    },
    //查看详情的删除 api
    delDetail(idArr) {
      myRequest(
        {
          method: "post",
          url: teacherinfodetail_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          methodName: "showList",
          modal: "showDetailEditModal",
          that: this,
        }
      );
    },
    //详情的修改路由页 api
    editDetailRoutePage(id) {
      myRequest({
        url: teacherinfodetail_input2,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailEditModal = true;
          this.$refs.moneyTable.clearSelection();
          this.dynamicValidateForm = res.data.data.vteacherinfodetail;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //上传文件自定义方法改变请求数据 api
    uploadFile() {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.fileurl = res.data.url;
        }
      });
    },
    //保存上传凭证 api
    ontechcredential(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: teacherinfodetail_save,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              modal: "showCredentialModal",
              isRefTableList: true,
            }
          );
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>